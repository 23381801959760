import React, { useMemo, useState } from 'react'
import ReactDOM from 'react-dom'

import styled from 'styled-components'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import { Col, Modal, Row } from 'antd'

import { HeadingThree, TextSmall } from './Typography'
import Button from './Button'

import Step1Image from '../assets/images/intro-image-1.png'
import Step2Image from '../assets/images/intro-image-2.png'
import Step3Image from '../assets/images/intro-image-3.png'

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 242px;
	min-width: 242px;

	align-items: center;

	& + & {
		margin-left: 82px;
	}

	& > ${HeadingThree} {
		margin-top: 32px;
	}

	@media(max-width: 800px) {
		&:not(.image) {
			height: auto;
			flex: 1;
			align-items: center;
			margin-top: 42px;
			margin-left: 0;
		}
	}
`

const Stepper = styled.div<{ step: number }>`
	height: 8px;
	display: flex;

	& > div {
		background: #E3E3E3;
		height: 8px;
		width: 8px;
		border-radius: 4px;
	}

	& > div + div {
		margin-left: 7px;
	}

	& > div:nth-of-type(${({ step }) => step + 1}) {
		background: ${({ theme }) => theme.colors.citrus};
	}
`

const Wrapper = styled.div`
	background: white;
	padding: 45px;
	height: 100%;

	display: flex;

	@media(max-width: 800px) {
		justify-content: space-between;
		flex-direction: column;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
	}
`

const getSteps = () => [{
	image: Step1Image,
	title: i18next.t('Introduction.step1.title'),
	description: i18next.t('Introduction.step1.description')
}, {
	image: Step2Image,
	title: i18next.t('Introduction.step2.title'),
	description: i18next.t('Introduction.step2.description')
}, {
	image: Step3Image,
	title: i18next.t('Introduction.step3.title'),
	description: i18next.t('Introduction.step3.description')
}]

type Props = {
	onComplete: () => void
}

const body = document.getElementById('root')

const Introduction = ({ onComplete }: Props) => {
	const [t] = useTranslation()
	const [step, setStep] = useState(0)

	const steps = useMemo(() => getSteps(), [])

	const stepData = steps[step]

	const handleNextStep = () => {
		if (step === 2) {
			onComplete()
		} else {
			setStep(step + 1)
		}
	}

	const content = (
		<Wrapper>
			<Column className={'image'}>
				<img src={stepData.image} alt={`${step}`}/>
			</Column>
			<Column>
				<HeadingThree>{stepData.title}</HeadingThree>
				<TextSmall className={'text-center'} style={{ minHeight: 48 }}>{stepData.description}</TextSmall>
				<Stepper step={step}>
					<div/><div/><div/>
				</Stepper>
				<Row gutter={16} justify={'end'} style={{ width: '100%' }}>
					{step > 0 &&
					<Col span={12}>
						<Button className={'secondary'} style={{ width: '100%' }} onClick={() => setStep(step - 1)}>
							{t('Introduction.back')}
						</Button>
					</Col>}
					<Col span={12}>
						<Button onClick={handleNextStep} style={{ width: '100%' }}>
							{t('Introduction.next')}
						</Button>
					</Col>
				</Row>
			</Column>
		</Wrapper>
	)

	if (window.innerWidth > 801) {
		return (
			<Modal
				width={801}
				visible={true}
				onCancel={onComplete}
				footer={null}
			>
				{content}
			</Modal>
		)
	}

	return ReactDOM.createPortal(content, body!)
}

export default Introduction
