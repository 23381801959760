import React, { useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'

import { Text, TextTiny } from '../../../components/Typography'
import { Input } from '../../../components/Form/Styled'

import Logo from '../../../assets/images/image-logo.png'
import Etiquette from '../../../assets/images/image-etiquette.png'

const ErrorText = styled(TextTiny)`
	color: ${({ theme }) => theme.colors.error};
`

const LoginContent = styled.div`
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;

	padding: 16px;

	& > img {
		margin-top: -30%;
		opacity: 0.7;
	}

	${Text} {
		margin-bottom: 32px;
	}

	${Input}:not(.error) {
		margin-bottom: 32px;
	}

	${ErrorText} {
		margin-bottom: 32px;
	}

	${Button} {
		width: 100%;
	}
`

const LoginWrapper = styled.div`
	position: relative;
	z-index: 2;
	border-radius: 16px 16px 0 0;
	background: white;

	overflow: hidden;
	height: calc(100% - 62px);
`

const Overlay = styled.div`
	z-index: 1;
	position: absolute;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 52.08%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, #252927 0%, rgba(37, 41, 39, 0.12) 100%);
	opacity: 0.5;
`

const Wrapper = styled.div`
	position: relative;
	height: 100%;
	background: linear-gradient(196.57deg, rgba(255, 255, 255, 0.094) 1.37%, rgba(255, 255, 255, 0.096) 100%), linear-gradient(163.39deg, #B1CA0B 0.89%, rgba(202, 196, 11, 0.9) 54.87%, rgba(161, 202, 11, 0.9) 99.59%), #FFFFFF;
	background-size: 100%;

	& > img {
		position: relative;
		z-index: 2;
		height: 32px;
		margin: 15px;
	}
`

type Props = {
	onLogin: (code: string) => Promise<any> | any
}

const ManualLogin = ({ onLogin }: Props) => {
	const [t] = useTranslation()
	const [code, setCode] = useState('')
	const [loading, setLoading] = useState(false)

	const [error, setError] = useState('')

	const handleLogin = async () => {
		setLoading(true)

		try {
			await onLogin(code)
			setLoading(false)
		} catch (e) {
			setError(t('LoginPage.loginError'))
			setLoading(false)
		}
	}

	return (
		<Wrapper>
			<img src={Logo} alt={'Elwis'}/>
			<LoginWrapper>
				<LoginContent>
					<img src={Etiquette} alt={'121245510'}/>
					<Text className={'bold text-center'}>
						{t('LoginPage.manualLoginMessage')}
					</Text>
					<Input
						autoFocus
						className={cx({ error })}
						value={code}
						onChange={(e) => setCode(e.target.value)}
						placeholder={t('LoginPage.inputPlaceholder')}
					/>
					{!!error && <ErrorText>{error}</ErrorText>}
					<Button
						onClick={handleLogin}
						disabled={loading || !code}
						className={cx({ loading })}
					>
						{t('LoginPage.login')}
					</Button>
				</LoginContent>
			</LoginWrapper>
			<Overlay/>
		</Wrapper>
	)
}

export default ManualLogin
