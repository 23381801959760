import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'

import { Col, Row } from 'antd'

import dayjs from 'dayjs'
import { formatDatetime } from '../../utils/helpers'

import {
	getCollections, getCollectionYardCollections, getContainersSummary, getSortingRate, statisticsActions
} from '../../redux/statistics'
import { getAuthUser } from '../../redux/authorization'

import StatisticsContainer from './components/StatisticsContainer'
import ListStatistics, { ExpandableSection } from './components/ListStatistics'

import StatisticsTooltip from './components/StatisticsTooltip'
import ContainersSummaryTable from './components/ContainersSummaryTable'

import { HeadingThree, Text, TextSmall } from '../../components/Typography'

import PieChartStatistics from './components/PieChartStatistics'
import ContainersSummarySmallTable from './components/ContainersSummarySmallTable'
import Button from '../../components/Button'

const CollapseButton = styled(Button)`
	position: fixed;
	bottom: 0;
	left: 0;
	height: 50px;
	width: 100%;
	background: white !important;
	z-index: 2;
	border-radius: 0;

	box-shadow: 0px -6px 21px 0px rgba(0,0,0,0.23) !important;
`

const PieCenterText = styled.div`
	width: 80px;
	text-align: center;
`

const StatisticsPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const [year, setYear] = useState<string | number>(dayjs().year())
	const [expandedSection, setExpandedSection] = useState<ExpandableSection>(null)

	const collectionPlace = useSelector(getAuthUser)
	const sortingRate = useSelector(getSortingRate)
	const collections = useSelector(getCollections)
	const collectionYardCollections = useSelector(getCollectionYardCollections)
	const containersSummary = useSelector(getContainersSummary)

	const sortingRateData = useMemo(() => map(sortingRate.data?.wasteTypes, (dataItem) => ({
		value: dataItem.rate, label: dataItem.name, color: `#${dataItem.color}`
	})), [sortingRate.data])

	const collectionsData = useMemo(() => map(collections.data?.collections, (item) => ({
		title: item.container.containerType.wasteType?.name || '-',
		subtitle: formatDatetime(item.scanDatetime),
		rightText: item.container.serialNumber || item.container.rfidSerial || item.container.code || item.container.rfid || '-',
		color: `#${item.container.containerType.wasteType?.color || 'FAFAFA'}`,
		weight: item?.weight,
		calculatedByCoefficient: item?.calculatedByCoefficient
	})), [collections.data])

	const collectionYardCollectionsData = useMemo(() => map(collectionYardCollections.data?.collections, (item) => ({
		title: item.container.containerType.wasteType?.name || '-',
		subtitle: formatDatetime(item.scanDatetime),
		rightText: `${item.weight} kg`,
		color: `#${item.container.containerType.wasteType?.color || 'FAFAFA'}`
	})), [collectionYardCollections.data])

	const sortingRateFilters = useMemo(() => ({
		options: Array.from({ length: dayjs().year() - 2019 + 1 }, (_, index) => ({
			value: 2019 + index,
			label: `${2019 + index}`
		})),
		value: year,
		onChange: setYear
	}), [year])

	useEffect(() => {
		if (collectionPlace.data) {
			dispatch(statisticsActions.loadCollections({ id: collectionPlace.data.id, filters: { page: 1, limit: 1000 } }))
			dispatch(statisticsActions.loadCollectionYardCollections({ id: collectionPlace.data.id, filters: { page: 1, limit: 1000 } }))
			dispatch(statisticsActions.loadContainersSummary({ id: collectionPlace.data.id }))
		}
	}, [dispatch, collectionPlace.data])

	useEffect(() => {
		if (collectionPlace.data) {
			dispatch(statisticsActions.loadSortingRate({ id: collectionPlace.data.id, filters: { year } }))
		}
	}, [dispatch, collectionPlace.data, year])

	const collectionsTooltip = useMemo(() => ({
		title: t('StatisticsPage.sortingRateTooltipTitle'),
		description: t('StatisticsPage.sortingRateTooltipDescription')
	}), [t])

	const collectionYardTooltip = useMemo(() => ({
		title: t('StatisticsPage.collectionTooltipTitle'),
		description: t('StatisticsPage.collectionTooltipDescription')
	}), [t])

	return (
		<>
			<Row gutter={[20, 20]}>
				<Col span={24} md={12} xl={8}>
					<StatisticsContainer isLoading={sortingRate.isLoading} className={'graph'}>
						<PieChartStatistics
							title={t('StatisticsPage.sortingRate')}
							emptyText={t('StatisticsPage.emptyCollections')}
							centerText={(
								<PieCenterText>
									<HeadingThree>{sortingRate.data?.sortingRate || 0} %</HeadingThree>
									<TextSmall>{t('StatisticsPage.sortingRate')}</TextSmall>
								</PieCenterText>
							)}
							tooltip={collectionsTooltip}
							filter={sortingRateFilters}
							data={sortingRateData}
						/>
					</StatisticsContainer>
				</Col>
				<Col span={24} md={12} xl={8}>
					<StatisticsContainer
						className={cx({ expanded: expandedSection === 'collections' })}
						isLoading={collections.isLoading}
					>
						<ListStatistics
							data={collectionsData}
							emptyText={t('StatisticsPage.emptyCollections')}
							title={t('StatisticsPage.collectionsTitle')}
							section={'collections'}
							expanded={expandedSection === 'collections'}
							onExpand={setExpandedSection}
							tooltip={collectionYardTooltip}
						/>
					</StatisticsContainer>
				</Col>
				<Col span={24} md={12} xl={8}>
					<StatisticsContainer
						className={cx({ expanded: expandedSection === 'collectionYard' })}
						isLoading={collectionYardCollections.isLoading}
					>
						<ListStatistics
							data={collectionYardCollectionsData}
							emptyText={t('StatisticsPage.emptyCollections')}
							title={t('StatisticsPage.collectionYardCollectionsTitle')}
							section={'collectionYard'}
							expanded={expandedSection === 'collectionYard'}
							onExpand={setExpandedSection}
							tooltip={{
								title: t('StatisticsPage.collectionYardCollectionsTooltipTitle'),
								description: t('StatisticsPage.collectionYardCollectionsTooltipDescription')
							}}
						/>
					</StatisticsContainer>
				</Col>
				<Col span={24} md={0}>
					<StatisticsContainer isLoading={containersSummary.isLoading}>
						<ContainersSummarySmallTable
							title={t('StatisticsPage.containersSummaryTitle')}
							emptyText={t('StatisticsPage.containersSummaryEmpty')}
							data={containersSummary.data}
						/>
					</StatisticsContainer>
				</Col>
				<Col span={0} md={24}>
					<Row gutter={[8, 8]}>
						<Col>
							<Text className={'bold'}>{t('StatisticsPage.containersSummaryTitle')}</Text>
						</Col>
						<Col>
							<StatisticsTooltip
								title={t('StatisticsPage.containersSummaryTitle')}
								description={t('StatisticsPage.containersSummaryDescription')}
							/>
						</Col>
						<Col span={24}>
							<StatisticsContainer className={'borderless'} isLoading={containersSummary.isLoading}>
								<ContainersSummaryTable summary={containersSummary.data}/>
							</StatisticsContainer>
						</Col>
					</Row>
				</Col>
			</Row>
			{!!expandedSection &&
			<CollapseButton
				className={'outline citrus'}
				onClick={() => setExpandedSection(null)}
			>
				{t('StatisticsPage.collapse')}
			</CollapseButton>}
		</>
	)
}

export default StatisticsPage
