import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { TokenPayload } from '../../types/data'

import { authorizationActions } from '../../redux/authorization'

import { HeadingOne, HeadingTwo, Text, TextTiny } from '../../components/Typography'
import { Input } from '../../components/Form/Styled'
import Button from '../../components/Button'

import ScanLogin from './components/ScanLogin'
import ManualLogin from './components/ManualLogin'

import { authorization } from '../../api'

import Badge from '../../assets/images/image-badge.png'
import Logo from '../../assets/images/image-logo.png'
import EtiquetteImage from '../../assets/images/image-etiquette.png'

const LoginWrapper = styled.div`
	height: 100%;
	border-radius: 24px 0 0 24px;
	background: white;
	width: 65%;
	z-index: 3;
	overflow: auto;

	@media(max-width: 964px) {
		display: none;
	}
`

const ErrorText = styled(TextTiny)`
	color: ${({ theme }) => theme.colors.error};
`

const LoginContent = styled.div`
	width: 50%;
	min-height: 100%;
	margin: auto;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	padding: 32px;

	${HeadingOne} {
		margin-bottom: 16px;
	}

	${Text} {
		margin-bottom: 32px;
	}

	${Input}:not(.error) {
		margin-bottom: 32px;
	}

	${ErrorText} {
		margin-bottom: 32px;
	}

	${Button} {
		width: 100%;
	}
`

const LoginOptions = styled.div`
	width: 100%;
	z-index: 3;
	background: white;
	border-radius: 16px 16px 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px;

	& > ${Button} {
		width: 100%;
	}

	${Button} + ${Button} {
		margin-top: 16px;
	}

	@media(min-width: 965px) {
		display: none;
	}
`

const Etiquette = styled.div`
	padding: 16px 26px;
	border: 1px solid ${({ theme }) => theme.colors.border};
	box-shadow: -10px 9px 21px rgba(128, 152, 213, 0.074983);
	border-radius: 8px;

	width: 234px;
	height: 294px;

	background: url("${EtiquetteImage}") center no-repeat;
	background-size: 183px 262px;
	margin-bottom: 32px;
`

const Title = styled.div`
	z-index: 3;
	flex: 1;
	background: transparent;
	color: white;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: 64px;

	& > ${HeadingTwo} {
		color: white;
		margin-top: 32px;
	}

	@media(max-width: 964px) {
		width: 100%;
		padding: 16px;
		justify-content: flex-end;

		& > img {
			height: 32px;
		}

		& > ${HeadingTwo} {
			margin-top: 16px;
		}
	}
`

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	background: linear-gradient(196.57deg, rgba(255, 255, 255, 0.094) 1.37%, rgba(255, 255, 255, 0.096) 100%), linear-gradient(163.39deg, #B1CA0B 0.89%, rgba(202, 196, 11, 0.9) 54.87%, rgba(161, 202, 11, 0.9) 99.59%), #FFFFFF;
	background-size: 100%;

	position: relative;

	&:before {
		z-index: 2;
		position: absolute;
		left: 0;
		bottom: 0;
		content: '';
		height: 100%;
		width: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 52.08%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, #252927 0%, rgba(37, 41, 39, 0.12) 100%);
		opacity: 0.5;
	}

	&:after {
		z-index: 0;
		position: absolute;
		left: 0;
		top: 0;
		content: '';
		background: url("${Badge}") top left no-repeat;
		width: 100%;
		height: 100%;
		background-size: auto;
	}

	@media(max-width: 964px) {
		flex-direction: column;
	}
`

const InfoLink = styled.a`
	font-weight: bold;
	position: absolute;
	left: 64px;
	bottom: 32px;
	color: white;
	z-index: 3;
	text-decoration: underline;

	@media(max-width: 964px) {
		position: relative;
		left: 0;
		bottom: 0;
		margin-bottom: 16px;
	}
`

const LoginPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const [error, setError] = useState('')
	const [code, setCode] = useState('')
	const [loading, setLoading] = useState(false)

	const [scanning, setScanning] = useState(false)
	const [manualLogin, setManualLogin] = useState(false)

	const handleLogin = useCallback(async (code: string) => {
		try {
			setLoading(true)
			const { data } = await authorization.login({ code })

			const tokenData: TokenPayload = jwtDecode(data.token)

			dispatch(authorizationActions.saveUser({ accessToken: data.token, tokenData, profile: data }))
			setLoading(false)
			setError('')
			return Promise.resolve()
		} catch (e) {
			setLoading(false)
			setError(t('LoginPage.loginError'))

			if (manualLogin || scanning) {
				return Promise.reject(e)
			}

			return Promise.resolve()
		}
	}, [dispatch, t, manualLogin, scanning])

	if (scanning) {
		return (
			<ScanLogin
				onLogin={handleLogin}
				onManualLogin={() => {
					setManualLogin(true)
					setScanning(false)
				}}
			/>
		)
	}

	if (manualLogin) {
		return <ManualLogin onLogin={handleLogin}/>
	}

	return (
		<Wrapper>
			<Title>
				<img src={Logo} alt={'Elwis'}/>
				<HeadingTwo>{t('LoginPage.teaserMessage')}</HeadingTwo>
			</Title>
			<InfoLink href="mailto:tomas.gibas@goodrequest.com">{t('LoginPage.infoMail', { email: 'elwis@meneodpadu.sk' })}</InfoLink>
			<LoginWrapper>
				<LoginContent>
					<HeadingOne>
						{t('LoginPage.title')}
					</HeadingOne>
					<Text
						className={'light-text'}
						dangerouslySetInnerHTML={{
							__html: t('LoginPage.titleText', { interpolation: { escapeValue: false } })
						}}
					/>
					<Etiquette/>
					<Input
						value={code}
						className={cx({ error })}
						onChange={(e) => setCode(e.target.value)}
						placeholder={t('LoginPage.inputPlaceholder')}
					/>
					{!!error && <ErrorText>{error}</ErrorText>}
					<Button
						onClick={() => handleLogin(code)}
						disabled={loading || !code}
						className={cx({ loading })}
					>
						{t('LoginPage.login')}
					</Button>
				</LoginContent>
			</LoginWrapper>
			<LoginOptions>
				<Button
					onClick={() => setScanning(true)}
				>
					{t('LoginPage.loginWithQR')}
				</Button>
				<Button
					onClick={() => setManualLogin(true)}
					className={'secondary'}
				>
					{t('LoginPage.loginManually')}
				</Button>
			</LoginOptions>
		</Wrapper>
	)
}

export default LoginPage
