import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { PersistState } from 'redux-persist/es/types'

import storageLocal from 'redux-persist/lib/storage'

import { StateAction } from '../types/state'

import { authorizationSlice, AuthorizationState } from './authorization'
import { statisticsSlice, StatisticsState } from './statistics'
import { introSlice, IntroState } from './intro'

type PersistPartial = {
	_persist: PersistState;
}

export type State = {
	authorization: AuthorizationState & PersistPartial
	intro: IntroState & PersistPartial
	statistics: StatisticsState
}

const rootReducer = combineReducers<State, StateAction<string>>({
	authorization: persistReducer({
		storage: storageLocal,
		key: 'authorization'
	}, authorizationSlice.reducer),
	intro: persistReducer({
		storage: storageLocal,
		key: 'intro'
	}, introSlice.reducer),
	statistics: statisticsSlice.reducer
})

export default rootReducer
