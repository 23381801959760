import styled from 'styled-components'

export const Label = styled.label``

export const Input = styled.input`
	height: 56px;
	font-size: 1rem;
	line-height: 150%;
	color: ${({ theme }) => theme.colors.electric};

	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.border};
	padding: 15px;

	width: 100%;

	outline: none;

	&.error {
		border-color: ${({ theme }) => theme.colors.error};
	}
`
