import { createGlobalStyle } from 'styled-components'

import theme from '../utils/theme'

import Background from '../assets/images/image-background.png'

export default createGlobalStyle`
	#root {
		height: 100%;
	}

	* {
		box-sizing: border-box;
	}

	html, body {
		font-family: 'Poppins', sans-serif;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		z-index: 1;
		position: relative;
		color: #47525E;
	}

	section.layout {
		background: url("${Background}") center no-repeat;
		background-size: cover;
		min-height: 100%;

		@media(max-width: 768px) {
			background: none;
		}
	}

	input, textarea, button {
		font-family: 'Poppins', sans-serif;
	}

	.dark-text {
		color: ${theme.colors.darkText};
	}

	.light-text {
		color: ${theme.colors.lightText};
	}

	.white-text {
		color: white;
	}

	.text-center {
		text-align: center;
	}

	.bold {
		font-weight: 600;
	}

	.medium {
		font-weight: 500;
	}

	// ant-corrections

	.ant-tooltip-inner {
		padding: 0;
	}
`
