import axios from 'axios'

export type Headers = { [name: string]: string | boolean }

type Config = {
	headers: Headers,
	params?: object
	baseURL?: string
	data?: object
	notifications?: Notifications
}

type DownloadConfig = Config & {
	responseType: 'blob'
}

type Notification = {
	message?: string
	description?: string
	ignore?: true
}

export type Notifications = {
	error?: Notification
	success?: Notification
}

function buildHeaders(items?: Headers): Headers {
	const headers = {
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': true,
		'Cache-Control': 'no-cache, no-store',
		Pragma: 'no-cache'
	}

	return {
		...headers,
		...items || {}
	}
}

function buildConfig(params?: object, accept?: string | null, headers?: Headers, data?: object, notifications?: Notifications): Config {
	const config: Config = {
		headers: buildHeaders(headers),
		notifications
	}

	if (accept) {
		config.headers.Accept = accept
	}

	if (params) {
		config.params = params
	}

	if (data) {
		config.data = data
	}

	return config
}

// const showNotification = (type: MESSAGE_TYPE, note: { message: string, description?: string | null }) => {
// 	switch (type) {
// 		case MESSAGE_TYPE.SUCCESS:
// 			notification.success(note)
// 			break
// 		case MESSAGE_TYPE.ERROR:
// 			notification.error(note)
// 			break
// 		case MESSAGE_TYPE.INFO:
// 			notification.info(note)
// 			break
// 		case MESSAGE_TYPE.WARNING:
// 			notification.warn(note)
// 			break
// 		default: break
// 	}
// }
//
// function successAction<T extends MessagesResponse>(item?: Notification) {
// 	return (resp: AxiosResponse<T>) => {
// 		const { data } = resp
//
// 		if (item?.ignore) {
// 			return resp
// 		}
//
// 		if (data.messages) {
// 			forEach(data.messages, (message) => {
// 				const note = {
// 					message: item?.message || i18next.t('defaultMessage') || '',
// 					description: item?.description || message.message
// 				}
// 				showNotification(message.type, note)
// 			})
// 		} else if (item && item.message) {
// 			notification.success({ message: item.message, description: item.description })
// 		}
//
// 		return resp
// 	}
// }
//
// function errorAction<T extends MessagesResponse>(item?: Notification) {
// 	return (e: AxiosError<T>) => {
// 		if (item?.ignore) {
// 			return Promise.reject(e)
// 		}
//
// 		if (e.response && e.response.data.messages) {
// 			const { data } = e.response
//
// 			forEach(data.messages, (message) => {
// 				const note = {
// 					message: item?.message || i18next.t('defaultErrorMessage') || '',
// 					description: item?.description || message.message
// 				}
// 				showNotification(message.type, note)
// 			})
// 		} else if (item && item.message) {
// 			notification.error({ message: item.message, description: item.description })
// 		}
//
// 		return Promise.reject(e)
// 	}
// }

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { string } accept header
 * @param { Object } headers
 * @param { Object } notifications
 * @return Promise response
 * Performs get request to url and returns callback with result
 */
export function getReq(path: string, params?: object, accept?: string, headers?: Headers, notifications?: Notifications) {
	return axios.get(path, buildConfig(params, accept, headers, notifications))
	// .then(successAction(notifications?.success))
	// .catch(errorAction(notifications?.error))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } data
 * @param { Object } headers
 * @param { Object } notifications
 * @return Promise response
 * Performs post request to url and returns callback with result
 */
export function postReq(path: string, data?: object, params?: object, headers?: Headers, notifications?: Notifications) {
	return axios.post(path, data || {}, buildConfig(params, null, headers, undefined, notifications))
	// .then(successAction(notifications?.success))
	// .catch(errorAction(notifications?.error))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } data body
 * @param { Object } headers
 * @return Promise response
 * @param { Object } notifications
 * Performs put request to url and returns callback with result
 */
export function putReq(path: string, data?: object, params?: object, headers?: Headers, notifications?: Notifications) {
	return axios.put(path, data || {}, buildConfig(params, null, headers, undefined, notifications))
	// .then(successAction(notifications?.success))
	// .catch(errorAction(notifications?.error))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } data body
 * @param { Object } headers
 * @param { Object } notifications
 * @return Promise response
 * Performs put request to url and returns callback with result
 */
export function patchReq(path: string, data?: object, params?: object, headers?: Headers, notifications?: Notifications) {
	return axios.patch(path, data || {}, buildConfig(params, null, headers, undefined, notifications))
	// .then(successAction(notifications?.success))
	// .catch(errorAction(notifications?.error))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } headers
 * @param { Object } data
 * @param { Object } notifications
 * @return Promise response
 * Performs delete request to url and returns callback with result
 */
export function deleteReq(path: string, params: object = {}, headers?: Headers, data?: object, notifications?: Notifications) {
	return axios.delete(path, buildConfig(params, null, headers, data, notifications))
	// .then(successAction(notifications?.success))
	// .catch(errorAction(notifications?.error))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } headers
 * @return Promise response
 * Performs delete request to url and returns callback with result
 */
export function downloadReq(path: string, params: object = {}, headers?: Headers) {
	const config: DownloadConfig = {
		headers: buildHeaders(headers),
		responseType: 'blob'
	}

	if (params) {
		config.params = params
	}

	return axios.get(path, config)
}
