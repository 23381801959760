import React, { Suspense } from 'react'
import axios from 'axios'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider, ExtendedStringifyOptions, transformSearchStringJsonSafe } from 'use-query-params'

import theme from './utils/theme'
import i18n from './utils/i18n'

import { store, persistor } from './utils/configureStore'

import { authorizationActions, getToken } from './redux/authorization'

import Loading from './components/Loading'
import Routes from './routes/Routes'
import GlobalStyles from './components/GlobalStyles'

const queryStringifyOptions: ExtendedStringifyOptions = {
	transformSearchString: transformSearchStringJsonSafe
}

axios.interceptors.request.use((config) => {
	const state = store.getState()
	const token = getToken(state)

	if (token && !config.headers.Authorization) {
		config.headers.Authorization = `Bearer ${token}`
	}

	return config
})

axios.interceptors.response.use((response) => response, (error) => {
	if (error.response && error.response.status === 401) {
		store.dispatch(authorizationActions.logoutUser())
	}
	return Promise.reject(error)
})

function App() {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles/>
			<Suspense fallback={<Loading/>}>
				<I18nextProvider i18n={i18n}>
					<PersistGate loading={<Loading/>} persistor={persistor}>
						<Provider store={store}>
							<BrowserRouter>
								<QueryParamProvider
									ReactRouterRoute={Route}
									stringifyOptions={queryStringifyOptions}
								>
									<Routes/>
								</QueryParamProvider>
							</BrowserRouter>
						</Provider>
					</PersistGate>
				</I18nextProvider>
			</Suspense>
		</ThemeProvider>
	)
}

export default App
