import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { State } from './index'
import { StateListType, StateType } from '../types/state'
import { Collection, ContainersSummary, SortingRate } from '../types/data'
import { collectionPlaces } from '../api'

export type StatisticsState = {
	sortingRate: StateType<SortingRate>
	containersSummary: StateType<ContainersSummary>
	collections: StateListType<{ collections: Collection[] }>
	collectionYardCollections: StateListType<{ collections: Collection[] }>
}

const initialState: StatisticsState = {
	sortingRate: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	containersSummary: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	collections: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	collectionYardCollections: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

type StatisticsArgs = { id: string | number }
type ListStatisticsArgs = StatisticsArgs & { filters: object }

const loadSortingRate = createAsyncThunk('statistics/loadSortingRate',
	async ({ id, filters }: ListStatisticsArgs) => {
		const { data } = await collectionPlaces.loadSortingRate(id, filters)

		return data
	})

const loadCollections = createAsyncThunk('statistics/loadCollections',
	async ({ id, filters }: ListStatisticsArgs) => {
		const { data } = await collectionPlaces.loadCollections(id, filters)

		return data
	})

const loadCollectionYardCollections = createAsyncThunk('statistics/loadCollectionYardCollections',
	async ({ id, filters }: ListStatisticsArgs) => {
		const { data } = await collectionPlaces.loadCollectionYardCollections(id, filters)

		return data
	})

const loadContainersSummary = createAsyncThunk('statistics/loadContainersSummary',
	async ({ id }: StatisticsArgs) => {
		const { data } = await collectionPlaces.loadContainersSummary(id)

		return data
	})

export const statisticsSlice = createSlice({
	name: 'statistics',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loadSortingRate.pending, (state) => {
			state.sortingRate = { ...state.sortingRate, isLoading: true }
		})

		builder.addCase(loadSortingRate.rejected, (state) => {
			state.sortingRate = { ...state.sortingRate, isLoading: false }
		})

		builder.addCase(loadSortingRate.fulfilled, (state, { payload }) => {
			state.sortingRate = { ...state.sortingRate, isLoading: false, data: payload }
		})

		builder.addCase(loadCollections.pending, (state) => {
			state.collections = { ...state.collections, isLoading: true }
		})

		builder.addCase(loadCollections.rejected, (state) => {
			state.collections = { ...state.collections, isLoading: false }
		})

		builder.addCase(loadCollections.fulfilled, (state, { payload }) => {
			state.collections = { ...state.collections, isLoading: false, data: payload }
		})

		builder.addCase(loadCollectionYardCollections.pending, (state) => {
			state.collectionYardCollections = { ...state.collectionYardCollections, isLoading: true }
		})

		builder.addCase(loadCollectionYardCollections.rejected, (state) => {
			state.collectionYardCollections = { ...state.collectionYardCollections, isLoading: false }
		})

		builder.addCase(loadCollectionYardCollections.fulfilled, (state, { payload }) => {
			state.collectionYardCollections = { ...state.collectionYardCollections, isLoading: false, data: payload }
		})

		builder.addCase(loadContainersSummary.pending, (state) => {
			state.containersSummary = { ...state.containersSummary, isLoading: true }
		})

		builder.addCase(loadContainersSummary.rejected, (state) => {
			state.containersSummary = { ...state.containersSummary, isLoading: false }
		})

		builder.addCase(loadContainersSummary.fulfilled, (state, { payload }) => {
			state.containersSummary = { ...state.containersSummary, isLoading: false, data: payload }
		})
	}
})

export const getSortingRate = (state: State) => state.statistics.sortingRate
export const getCollections = (state: State) => state.statistics.collections
export const getCollectionYardCollections = (state: State) => state.statistics.collectionYardCollections
export const getContainersSummary = (state: State) => state.statistics.containersSummary

export const statisticsActions = {
	...statisticsSlice.actions,
	loadSortingRate,
	loadCollections,
	loadContainersSummary,
	loadCollectionYardCollections
}
