import thunk from 'redux-thunk'

import { Middleware } from 'redux'
import { createLogger } from 'redux-logger'
import { persistStore } from 'redux-persist'

import { configureStore } from '@reduxjs/toolkit'

import rootReducer, { State } from '../redux'

import { StateAction } from '../types/state'

function loggerFilter(getState: () => State, action: StateAction<string>) {
	if (action.type.startsWith('persist')) {
		return false
	}
	if (action.type === '@@redux-form/REGISTER_FIELD') {
		return false
	}
	return action.type !== '@@redux-form/UNREGISTER_FIELD'
}

let middleware: Middleware[] = [thunk]

if (process.env.NODE_ENV === 'development') {
	const logger = createLogger({
		collapsed: true,
		duration: true,
		predicate: loggerFilter
	})

	middleware = [...middleware, logger]
}

export const store = configureStore({
	reducer: rootReducer,
	middleware,
	devTools: process.env.NODE_ENV === 'development'
})

// @ts-ignore
export const persistor = persistStore(store)
