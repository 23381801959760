import React from 'react'
import styled from 'styled-components'

import { map } from 'lodash'

const Dot = styled.div<{ color: string }>`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin-right: 5px;
	background-color: ${({ color }) => `${color}` || 'black'};
`

const ValueItem = styled.div`
	font-weight: normal;
	margin-left: 5px;
`

const LegendItem = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
`

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

`

type Props = {
	items: {
		label: string
		value: string
		color: string
	}[]
}

const ChartLegend = ({ items }: Props) => (
	<Wrapper>
		{map(items, (item) => (
			<LegendItem key={item.label}>
				<Dot color={item.color}/>
				<span>{item.label}</span>
				<ValueItem>{item.value}</ValueItem>
			</LegendItem>
		))}
	</Wrapper>
)

export default ChartLegend
