import styled from 'styled-components'

import LoadingIcon from '../assets/icons/loading-icon.svg'

const Button = styled.button`
	font-size: 1rem;
	letter-spacing: 0.4px;
	cursor: pointer;

	color: white;
	background: ${({ theme }) => theme.colors.citrus};

	height: 48px;

	border-radius: 8px;
	box-shadow: 0 33px 63px rgba(83, 48, 1, 0.03), 0 13.7866px 26.3199px rgba(83, 48, 1, 0.0215656), 0 7.37098px 14.0719px rgba(83, 48, 1, 0.0178832), 0 4.13211px 7.88858px rgba(83, 48, 1, 0.015), 0 2.19453px 4.18956px rgba(83, 48, 1, 0.0121168), 0 0.913195px 1.74337px rgba(83, 48, 1, 0.00843437);

	border: 1px solid ${({ theme }) => theme.colors.citrus};
	outline: none;

	text-align: center;
	padding: 0 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	& > img + span, & > span + img {
		margin-left: 4px;
	}

	&.secondary {
		box-shadow: none;
		color: ${({ theme }) => theme.colors.electric};
		border: 1px solid ${({ theme }) => theme.colors.electric};
		background: white;

		&:after {
			filter: brightness(0);
		}
	}

	&.outline {
		border: none;
		box-shadow: none;
		background: none;
		color: ${({ theme }) => theme.colors.electric};

		&:after {
			filter: brightness(0);
		}
	}

	&.citrus {
		color: ${({ theme }) => theme.colors.citrus};

		&::after {
			filter: none;
		}
	}

	&::after {
		transition: width, margin-left 200ms ease-in-out;
		display: inline-block;
		content: '';
		z-index: 1;
		background: url("${LoadingIcon}") center no-repeat;
		background-size: 12px;
		filter: brightness(0) invert(1);

		width: 0;
		height: 12px;

		animation-name: spin;
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;

		@keyframes spin {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}

	&.loading {
		pointer-events: none;

		&::after {
			width: 12px;
			margin-left: 10px;
		}
	}

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
`

export default Button
