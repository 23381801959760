import { getReq } from '../utils/request'
import { ListResponse, Response } from '../types/api'
import { Collection, ContainersSummary, ID, SortingRate } from '../types/data'

export default {
	loadCollectionPlace: (id: ID) => getReq(`/api/web/collectionsPlace/${id}`),
	loadSortingRate: (id: ID, filters: object): Response<SortingRate> => getReq(`/api/web/collection-places/${id}/sorting-rate`, filters),
	loadCollections: (id: ID, filters: object): ListResponse<{ collections: Collection[] }> => getReq(`/api/web/collection-places/${id}/collections`, filters),
	loadCollectionYardCollections: (id: ID, filters: object): ListResponse<{ collections: Collection[] }> => getReq(`/api/web/collection-places/${id}/collections/collection-yard`, filters),
	loadContainersSummary: (id: ID): Response<ContainersSummary> => getReq(`/api/web/collection-places/${id}/containers-summary`)
}
