import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`

`

const Loading = () => (
	<Wrapper>
		Loading...
	</Wrapper>
)

export default Loading
