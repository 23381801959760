import { createSlice } from '@reduxjs/toolkit'

import { State } from './index'

export type IntroState = {
	completed: boolean
}

const initialState: IntroState = {
	completed: false
}

export const introSlice = createSlice({
	name: 'intro',
	initialState,
	reducers: {
		completeIntro: (state: IntroState) => {
			state.completed = true
		}
	}
})

export const getIntroCompleted = (state: State) => state.intro.completed

export const introActions = {
	...introSlice.actions
}
