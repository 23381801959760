import React from 'react'
import ReactDOM from 'react-dom'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import App from './App'
import reportWebVitals from './reportWebVitals'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'antd/es/style/core/index.less'
import 'antd/es/grid/style/index.less'
import 'antd/es/tooltip/style/index.less'
import 'antd/es/select/style/index.less'
import 'antd/es/modal/style/index.less'

import './styles/global.css'

require('dayjs/locale/sk')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Bratislava')

ReactDOM.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
