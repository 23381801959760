import React from 'react'
import styled from 'styled-components'

import { Tooltip } from 'antd'

import { Text } from '../../../components/Typography'

import TooltipIcon from '../../../assets/icons/icon-tooltip.svg'

const TooltipOverlay = styled.div`
	padding: 24px;
	border-radius: 10px;
	background: ${({ theme }) => theme.colors.darkText};

	& > ${Text} {
		color: white;
	}

	& > ${Text} + ${Text} {
		margin-top: 8px;
		opacity: 0.8;
	}
`

type Props = {
	title: string
	description: string
}

const StatisticsTooltip = ({ title, description }: Props) => (
	<Tooltip
		overlay={(
			<TooltipOverlay>
				<Text className={'bold'}>{title}</Text>
				<Text>{description}</Text>
			</TooltipOverlay>
		)}
	>
		<img src={TooltipIcon} alt={'i'}/>
	</Tooltip>
)

export default StatisticsTooltip
