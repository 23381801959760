import React from 'react'
import { Switch } from 'react-router'

import { Route } from 'react-router-dom'

import AuthRoute from './AuthRoute'

import NotFoundPage from '../pages/Error/NotFound'
import StatisticsPage from '../pages/Statistics/StatisticsPage'

const Routes = () => (
	<Switch>
		<AuthRoute
			exact
			path={'/'}
			component={StatisticsPage}
		/>

		<Route
			path={'/'}
			component={NotFoundPage}
		/>
	</Switch>
)

export default Routes
