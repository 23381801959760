import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'

import { ContainersSummary } from '../../../types/data'

const ConsumptionTitle = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	transform: rotate(-90deg);
	width: 100%;
	text-align: center;
`

const Row = styled.div`
	min-height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	white-space: nowrap;

	&.consumption {
		padding: 0;
		max-height: unset;
	}

	&.data-header {
		background-color: #F1EFF970;
	}

	&.active {
		background-color: ${({ theme }) => theme.colors.citrus}30;
	}
`

const Dot = styled.div<{ color: string }>`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin-right: 10px;
	background-color: ${({ color }) => `#${color}` || 'black'};
`

const Column = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	&.legend {
		width: 200px;
		background-color: #F9F8FC70;
		border-right: 1px solid #E3E3E370;
	}

	&.data-wrapper {
		flex: 1;
		flex-direction: row;
		overflow-x: auto;
	}

	&.data {
		flex: 1;
		min-width: 120px;
	}

	&.consumption {
		border-bottom: 1px solid #E3E3E370;
		border-right: 1px solid #E3E3E370;
		height: 100%;
		min-width: 28px;
		background-color: #F1EFF970;
	}

	&.summary {
		border-left: 1px solid #E3E3E370;
		width: 200px;
	}

	&:not(.legend) > ${Row}:not(.data-header):not(:last-of-type) {
		border-bottom: 1px solid #E3E3E370;
		flex: 1;
	}
`

const Wrapper = styled.div`
	display: flex;
	border-radius: 10px;
	overflow: hidden;
	border: 1px solid #E3E3E370;
`

type Props = {
	summary: ContainersSummary | null
}

const ContainersSummaryTable = ({ summary }: Props) => {
	const [t] = useTranslation()

	if (!summary) {
		return null
	}

	return (
		<Wrapper>
			<Column className={'legend'}>
				<Row style={{ borderBottom: '1px solid #E3E3E370' }}>&nbsp;</Row>
				<Row className={'consumption'}>
					<Column className={'consumption'}>
						<ConsumptionTitle>
							{t('StatisticsPage.consumption')}
						</ConsumptionTitle>
					</Column>
					<Column className={'data'}>
						<Row>
							{t('StatisticsPage.months', { count: 3 })}
						</Row>
						<Row>
							{t('StatisticsPage.months', { count: 6 })}
						</Row>
						<Row style={{ borderBottom: '1px solid #E3E3E370' }}>
							{t('StatisticsPage.months', { count: 12 })}
						</Row>
					</Column>
				</Row>
				<Row>
					{t('StatisticsPage.unused')}
				</Row>
			</Column>
			<Column className={'data-wrapper'}>
				{map(summary.oneTimeContainersSummary, ({ name, color, activeCount, lastSixMonths, lastThreeMonths, lastTwelveMonths }, index) => (
					<Column className={'data'} key={index}>
						<Row className={'data-header'}><Dot color={color}/><span>{name}</span></Row>
						<Row>{lastThreeMonths}</Row>
						<Row>{lastSixMonths}</Row>
						<Row>{lastTwelveMonths}</Row>
						<Row className={'active'}>{activeCount}</Row>
					</Column>
				))}
			</Column>
			<Column className={'summary'}>
				<Row className={'data-header'}>{t('StatisticsPage.permanentContainers')}</Row>
				<Row>{summary.otherContainersSummary.permanentCount || '0'}</Row>
				<Row>&nbsp;</Row>
				<Row className={'data-header'} style={{ borderBottom: '1px solid #E3E3E370' }}>{t('StatisticsPage.universalContainers')}</Row>
				<Row>{summary.otherContainersSummary.universalCount || '0'}</Row>
			</Column>
		</Wrapper>
	)
}

export default ContainersSummaryTable
