import { Theme } from 'styled-components'

const theme: Theme = {
	colors: {
		citrus: '#B1CA0B',
		darkText: '#252927',
		lightText: '#525766',
		electric: '#59605C',
		error: '#EC3639',
		border: '#D1E0D9',
		lightBorder: '#E3E3E3'
	}
}

export default theme
