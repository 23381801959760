import React, { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { authorizationActions, getTokenData } from '../redux/authorization'
import { getIntroCompleted, introActions } from '../redux/intro'

import LoginPage from '../pages/Authorization/LoginPage'
import Layout from '../components/Layout'
import Introduction from '../components/Introduction'

type Props = RouteProps & {
	component: React.ComponentType
}

const AuthRoute = ({ component, ...props }: Props) => {
	const tokenData = useSelector(getTokenData)
	const dispatch = useDispatch()
	const introCompleted = useSelector(getIntroCompleted)

	const now = new Date().valueOf() / 1000

	const Component = component

	useEffect(() => {
		const now = new Date().valueOf() / 1000

		if (!tokenData || now > tokenData?.exp) {
			dispatch(authorizationActions.logoutUser())
		}
	}, [dispatch, tokenData])

	if (!introCompleted) {
		return (
			<Route
				{...props}
				render={(routeProps) => (
					<Layout>
						<Component {...routeProps}/>
						<Introduction
							onComplete={() => dispatch(introActions.completeIntro())}
						/>
					</Layout>
				)}
			/>
		)
	}

	if (!tokenData || tokenData.exp < now) {
		return (
			<Route
				{...props}
				render={() => (
					<LoginPage/>
				)}
			/>
		)
	}

	return (
		<Route
			{...props}
			render={(routeProps) => (
				<Layout>
					<Component {...routeProps}/>
				</Layout>
			)}
		/>
	)
}

export default AuthRoute
