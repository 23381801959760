import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { authorizationActions, getAuthUser } from '../redux/authorization'

import Button from './Button'

import { HeadingThree, TextTiny } from './Typography'

import Logo from '../assets/images/image-logo-green.png'
import Logout from '../assets/icons/icon-logout.svg'

const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	padding: 16px 64px;

	@media(max-width: 1440px) {
		padding: 16px 32px;
	}

    @media(max-width: 575px) {
        flex-wrap: wrap;
		padding: 8px 16px;
    }
`

const Address = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1;
	margin-left: 64px;

	@media(max-width: 575px) {
	  	margin-top: 16px;
	  	min-width: 100%;
		margin-left: 0;
	  	order: 3;
	}
`

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 0;
`

const Content = styled.div`
	width: 100%;

	padding: 32px 64px;

  	@media(max-width: 1440px) {
	  	padding: 16px 32px;
    }

    @media(max-width: 575px) {
	    padding: 8px 16px;
    }
`

const Layout = ({ children }: PropsWithChildren<{}>) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const collectionPlace = useSelector(getAuthUser)

	return (
		<Wrapper className={'layout'}>
			<Header>
				<img src={Logo} alt={'Elwis'}/>
				<Address>
					<TextTiny>
						{t('Layout.yourHome')}
					</TextTiny>
					<HeadingThree>
						{collectionPlace.data?.address}
					</HeadingThree>
				</Address>
				<Button
					onClick={() => dispatch(authorizationActions.logoutUser())}
					className={'outline'}
				>
					<img src={Logout} alt={'Logout'}/>
					<span>{t('Layout.logout')}</span>
				</Button>
			</Header>
			<Content>
				{children}
			</Content>
		</Wrapper>
	)
}

export default Layout
