import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { CollectionPlace, TokenPayload } from '../types/data'
import { StateType } from '../types/state'
import { State } from './index'

export type AuthorizationState = {
	token: string | null
	data: TokenPayload | null
	authUser: StateType<CollectionPlace | null>
}

const initialState: AuthorizationState = {
	token: null,
	data: null,
	authUser: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export const authorizationSlice = createSlice({
	name: 'authorization',
	initialState,
	reducers: {
		logoutUser: (state: AuthorizationState) => {
			state.token = null
			state.data = null
			state.authUser = initialState.authUser
		},
		saveUser: (state: AuthorizationState, action: PayloadAction<{ accessToken: string, tokenData: TokenPayload, profile: CollectionPlace }>) => {
			state.authUser.data = action.payload.profile
			state.token = action.payload.accessToken
			state.data = action.payload.tokenData
		}
	}
})

export const getToken = (state: State) => state.authorization.token
export const getTokenData = (state: State) => state.authorization.data
export const getAuthUser = (state: State) => state.authorization.authUser

export const authorizationActions = {
	...authorizationSlice.actions
}
