import styled from 'styled-components'

export const HeadingOne = styled.h1`
	margin: 0;
	font-size: 2rem;
	line-height: 150%;
	font-weight: bold;
	letter-spacing: -0.25px;

	color: ${({ theme }) => theme.colors.darkText};
`

export const HeadingTwo = styled.h2`
	margin: 0;
	font-size: 1.5rem;
	line-height: 150%;
	font-weight: bold;
	letter-spacing: -0.25px;

	color: ${({ theme }) => theme.colors.darkText};
`

export const HeadingThree = styled.h3`
	margin: 0;
	font-size: 1.125rem;
	line-height: 135%;
	font-weight: 500;

	color: ${({ theme }) => theme.colors.darkText};
`

export const Text = styled.p`
	margin: 0;
	font-size: 1rem;
	line-height: 150%;

	color: ${({ theme }) => theme.colors.darkText};
`

export const TextSmall = styled.p`
	margin: 0;
	font-size: 0.875rem;
	line-height: 115%;

	color: ${({ theme }) => theme.colors.darkText};
`

export const TextTiny = styled.p`
	margin: 0;
	font-size: 0.75rem;
	line-height: 150%;

	color: ${({ theme }) => theme.colors.darkText};
`
