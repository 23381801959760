import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import QrReader from 'react-qr-reader'

import { HeadingThree, TextSmall, Text } from '../../../components/Typography'

import Button from '../../../components/Button'

import Badge from '../../../assets/images/image-badge.png'

const Overlay = styled.div`
	background: #00000080;

	width: 100%;
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	padding: 16px 16px 64px 16px;

	text-align: center;

	& > ${HeadingThree} {
		margin-bottom: 16px;
	}
`

const Wrapper = styled.div`
	position: relative;
	height: 100%;
	z-index: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	& > video {
		width: 100%;
	}
`

const Loading = styled(Text)`
	position: absolute;
	color: white;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: ${({ theme }) => theme.colors.citrus};
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`

const ErrorWrapper = styled.div`
	position: absolute;
	color: white;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: white;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 2;

	&:after {
		position: absolute;
		left: 0;
		top: 0;
		content: '';
		filter: brightness(0.1) invert(1);
		background: url("${Badge}") top left no-repeat;
		width: 100%;
		height: 100%;
		background-size: auto;
		z-index: 1;
	}

	& > ${Text} {
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${({ theme }) => theme.colors.error};
		flex: 1;
		z-index: 2;
	}
`

const LoginOptions = styled.div`
	width: 100%;
	background: white;
	border-radius: 16px 16px 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px;
	z-index: 2;

	& > ${Button} {
		width: 100%;
	}

	${Button} + ${Button} {
		margin-top: 16px;
	}
`

type Props = {
	onLogin: (code: string) => Promise<any> | any
	onManualLogin: () => void
}

const ScanLogin = ({ onLogin, onManualLogin }: Props) => {
	const [t] = useTranslation()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [delay, setDelay] = useState<number | false>(false)

	useEffect(() => {
		setTimeout(() => {
			setDelay(1000)
		}, 250)
	}, [])

	const handleDecode = useCallback(async (result: any) => {
		if (result) {
			setDelay(false)

			try {
				setLoading(true)
				await onLogin(result)
			} catch (e) {
				setError(true)
				setLoading(false)
			}
		}
	}, [onLogin])

	const handleError = useCallback(() => {
		setError(true)
	}, [])

	return (
		<Wrapper>
			{loading &&
			<Loading>{t('LoginPage.loading')}</Loading>}
			{error &&
			<ErrorWrapper>
				<Text>{t('LoginPage.scanError')}</Text>
				<LoginOptions>
					<Button
						onClick={() => {
							setDelay(1000)
							setLoading(false)
							setError(false)
						}}
					>
						{t('LoginPage.retryScan')}
					</Button>
					<Button
						onClick={onManualLogin}
						className={'secondary'}
					>
						{t('LoginPage.loginManually')}
					</Button>
				</LoginOptions>
			</ErrorWrapper>}
			<Overlay/>
			{(!loading && !error) &&
			<QrReader
				delay={delay}
				showViewFinder={false}
				resolution={1200}
				facingMode={'environment'}
				style={{ width: '100%' }}
				onError={handleError}
				onScan={handleDecode}
			/>}
			<Overlay>
				<HeadingThree className={'white-text'}>{t('LoginPage.scanTitle')}</HeadingThree>
				<TextSmall className={'white-text'}>{t('LoginPage.scanMessage')}</TextSmall>
			</Overlay>
		</Wrapper>
	)
}

export default ScanLogin
