import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`

`

const NotFoundPage = () => (
	<Wrapper>
		404
	</Wrapper>
)

export default NotFoundPage
