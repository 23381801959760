export enum MESSAGE_TYPE {
	INFO = 'INFO',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS'
}

export enum NAMESPACE {
	loc = 'loc',
	paths = 'paths'
}

export enum DIALOG {

}

export enum MODAL {

}
