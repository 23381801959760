import dayjs from 'dayjs'
import i18next from 'i18next'

// eslint-disable-next-line import/prefer-default-export
export const formatDatetime = (date: string | Date) => {
	if (!date) {
		return '-'
	}
	return dayjs(date).format(i18next.t('datetimeFormat'))
}
